<template scoped>
  <div>
    <el-form-item
      :rules="rules.flowLogic"
      label="FlowLogic"
      prop="execute.flowLogic.id"
      :style="{ marginTop: '10px' }"
    >
      <el-select
        v-model="logic.execute.flowLogic.id"
        :style="{ width: '100%' }"
        filterable
        remote
        reserve-keyword
        placeholder="FlowLogic"
        :remote-method="queryFlowLogic"
        :loading="remote.flowLogic.loading"
      >
        <el-option
          v-for="item in remote.flowLogic.suggestion"
          :key="item.id"
          :label="item.description"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </el-form-item>
  </div>
</template>
<script scoped>
export default {
  props: {
    logic: Object,
    endpoint: String,
    productId: String,
  },
  methods: {
    validator_flowLogic: function (rule, value, callback) {
      if (!value) {
        callback(new Error("the flowLogic should not be empty"));
      } else {
        callback();
      }
    },
    queryFlowLogic: function () {
      var $this = this;
      this.remote.flowLogic.loading = true;
      fetch(
        `${this.endpoint}/api/Backend/FlowLogic?FlowId=${this.logic.flowId}&Page=1&PageSize=100`
      )
        .then(function (request) {
          return request.json();
        })
        .then(function (response) {
          $this.remote.flowLogic.suggestion = response.result.result;
          $this.remote.flowLogic.loading = false;
        });
    },
  },
  data: function () {
    return {
      rules: {
        flowLogic: [{ validator: this.validator_flowLogic, trigger: "blur" }],
      },
      remote: {
        flowLogic: {
          loading: false,
          suggestion: [],
        },
      },
    };
  },
};
</script>